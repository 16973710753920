import React from 'react'
import * as queryString from 'query-string'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/pipeable'
import Select from 'react-select'
import { DatePicker, TimePicker } from 'react-rainbow-components'

import {
  CfPaymentForm,
  CF_PLAN,
} from '../components/payments/cortiz-fitness-payment-form'
import Layout from '../components/layout'

import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { Location } from '../components/nav/nav.types'
import { newIO } from '../util/io'
import { format } from 'date-fns'
import { useSpring, animated } from 'react-spring'

const cortizFitnessOptions = [
  { value: 'single_session', label: 'Single Session' },
  { value: 'base', label: 'Base' },
  { value: 'premium', label: 'Premium' },
  { value: 'platinum', label: 'Platinum' },
]

const initialState = {
  date: new Date(),
  locale: { name: 'en-US', label: 'English (US)' },
}

const theme = {
  rainbow: {
    palette: {
      brand: '#5c56b6',
    },
  },
}

const stripePromise = loadStripe(
  process.env.GATSBY_STRIPE_PUBLISHABLE_KEY || '',
  {
    stripeAccount: process.env.GATSBY_CORTIZ_FITNESS_CONNECT_ACCOUNT_ID,
  }
)

const CfSignUp = ({ location }: { location: Location }) => {
  const [plan, setPlan] = React.useState<O.Option<string>>(O.none)
  const [idealStartTime1, setIdealStartTime1] = React.useState<
    O.Option<string>
  >(O.none)
  const [idealStartTime2, setIdealStartTime2] = React.useState<
    O.Option<string>
  >(O.none)
  const [idealStartTime3, setIdealStartTime3] = React.useState<
    O.Option<string>
  >(O.none)
  const [idealStartDate1, setIdealStartDate1] = React.useState<
    O.Option<{ date: Date; formatted: string }>
  >(O.none)
  const [idealStartDate2, setIdealStartDate2] = React.useState<
    O.Option<{ date: Date; formatted: string }>
  >(O.none)
  const [idealStartDate3, setIdealStartDate3] = React.useState<
    O.Option<{ date: Date; formatted: string }>
  >(O.none)
  const [idealDaysOfTheWeek, setIdealDaysOfTheWeek] = React.useState<
    O.Option<Array<string>>
  >(O.none)

  React.useEffect(() => {
    const queryParams = location.search ? O.some(location.search) : O.none
    pipe(
      queryParams,
      O.fold(
        () => newIO(() => setPlan(O.none)),
        params =>
          newIO(() => {
            const parsedParams = queryString.parse(params)
            parsedParams.plan && setPlan(O.some(parsedParams.plan))
          })
      )
    )()
  }, [location])

  const hasSelectedDay = (day: string) =>
    pipe(
      idealDaysOfTheWeek,
      O.fold(
        () => false,
        selectedDays => selectedDays.includes(day)
      )
    )

  const handleSelectedDay = (day: string) =>
    pipe(
      idealDaysOfTheWeek,
      O.fold(
        () => newIO(() => setIdealDaysOfTheWeek(O.some([day]))),
        selectedDays =>
          newIO(() => {
            selectedDays.includes(day)
              ? setIdealDaysOfTheWeek(
                  O.some(selectedDays.filter(d => d !== day))
                )
              : setIdealDaysOfTheWeek(O.some([...selectedDays, day]))
          })
      )
    )

  const isServiceOfferedForSelectedPlan = (predicate: boolean) =>
    predicate ? (
      <svg
        className="h-6 w-6 text-green-500"
        stroke="currentColor"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M5 13l4 4L19 7"
        />
      </svg>
    ) : (
      <svg
        className="h-4 w-4 text-gray-300 mt-1 ml-1"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z" />
      </svg>
    )

  const ServicesPerPlan = () => {
    return (
      <div className="grid gap-4 grid-cols-2 mt-2 mx-4">
        <ul>
          <li className="flex items-start mb-4">
            <div className="flex-shrink-0">
              {isServiceOfferedForSelectedPlan(
                O.fold(
                  () => false,
                  p =>
                    p === 'single_session' ||
                    p === 'base' ||
                    p === 'premium' ||
                    p === 'platinum'
                )(plan)
              )}
            </div>
            <p className="ml-3 text-base leading-6 font-medium text-gray-400">
              in person or live remote streaming{' '}
              {O.fold(
                () => 'session',
                p =>
                  p === 'base' || p === 'premium' || p === 'platinum'
                    ? 'sessions'
                    : 'session'
              )(plan)}{' '}
            </p>
          </li>
          {O.fold(
            () => null,
            p =>
              p === 'single_session' ? (
                <li className="flex items-start my-4">
                  <div className="flex-shrink-0">
                    <svg
                      className="h-6 w-6 text-green-500"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>
                  <p className="ml-3 text-base leading-6 font-medium text-gray-400">
                    single 45min-55min workout session
                  </p>
                </li>
              ) : null
          )(plan)}
          <li className="flex items-start">
            <div className="flex-shrink-0">
              {isServiceOfferedForSelectedPlan(
                O.fold(
                  () => false,
                  p => p === 'base' || p === 'premium' || p === 'platinum'
                )(plan)
              )}
            </div>
            <p className="ml-3 text-base leading-6 font-medium text-gray-400">
              {O.fold(
                () => '12',
                p =>
                  p === 'base'
                    ? '8'
                    : p === 'premium'
                    ? '12'
                    : p === 'platinum'
                    ? '16'
                    : '12'
              )(plan)}{' '}
              sessions a month
            </p>
          </li>
          <li className="mt-4 flex items-start">
            <div className="flex-shrink-0">
              {isServiceOfferedForSelectedPlan(
                O.fold(
                  () => false,
                  p => p === 'base' || p === 'premium' || p === 'platinum'
                )(plan)
              )}
            </div>
            <p className="ml-3 text-base leading-6 font-medium text-gray-400">
              {O.fold(
                () => '3',
                p =>
                  p === 'base'
                    ? '2'
                    : p === 'premium'
                    ? '3'
                    : p === 'platinum'
                    ? '4'
                    : '3'
              )(plan)}{' '}
              sessions per week
            </p>
          </li>
          <li className="mt-4 flex items-start">
            <div className="flex-shrink-0">
              {isServiceOfferedForSelectedPlan(
                O.fold(
                  () => false,
                  p => p === 'premium' || p === 'platinum'
                )(plan)
              )}
            </div>
            <p className="ml-3 text-base leading-6 font-medium text-gray-400">
              Save{' '}
              {O.fold(
                () => '$60 over base plan',
                p =>
                  p === 'premium'
                    ? '$60 over base plan'
                    : p === 'platinum'
                    ? '$120 over premium plan'
                    : '$60 over base plan'
              )(plan)}{' '}
            </p>
          </li>
        </ul>
        <ul>
          <li className="flex items-start">
            <div className="flex-shrink-0">
              {isServiceOfferedForSelectedPlan(
                O.fold(
                  () => false,
                  p => p === 'base' || p === 'premium' || p === 'platinum'
                )(plan)
              )}
            </div>
            <p className="ml-3 text-base leading-6 font-medium text-gray-400">
              Nutrition plan
            </p>
          </li>
          <li className="mt-4 flex items-start">
            <div className="flex-shrink-0">
              {isServiceOfferedForSelectedPlan(
                O.fold(
                  () => false,
                  p => p === 'premium' || p === 'platinum'
                )(plan)
              )}
            </div>
            <p className="ml-3 text-base leading-6 font-medium text-gray-400">
              {O.fold(
                () => 'Monthly',
                p =>
                  p === 'premium'
                    ? 'Monthly'
                    : p === 'platinum'
                    ? 'Weekly'
                    : 'Monthly'
              )(plan)}{' '}
              body / performance analysis
            </p>
          </li>
          <li className="mt-4 flex items-start">
            <div className="flex-shrink-0">
              {isServiceOfferedForSelectedPlan(
                O.fold(
                  () => false,
                  p => p === 'premium' || p === 'platinum'
                )(plan)
              )}
            </div>
            <p className="ml-3 text-base leading-6 font-medium text-gray-400">
              At home workout log
            </p>
          </li>
          <li className="mt-4 flex items-start">
            <div className="flex-shrink-0">
              {isServiceOfferedForSelectedPlan(
                O.fold(
                  () => false,
                  p => p === 'platinum'
                )(plan)
              )}
            </div>
            <p className="ml-3 text-base leading-6 font-medium text-gray-400">
              Macro Count
            </p>
          </li>
          <li className="mt-4 flex items-start">
            <div className="flex-shrink-0">
              {isServiceOfferedForSelectedPlan(
                O.fold(
                  () => false,
                  p => p === 'platinum'
                )(plan)
              )}
            </div>
            <p className="ml-3 text-base leading-6 font-medium text-gray-400">
              Healthy and delicious Recipes
            </p>
          </li>
        </ul>
      </div>
    )
  }

  const springOpacity = useSpring({
    from: { opacity: 0, transform: 'translateX(90px)' },
    to: { opacity: 1, transform: 'translateX(0)' },
  })

  const parseMillitaryTime = (time: string) =>
    pipe(
      time,
      s => s.split(':'),
      time_ =>
        `${((Number(time_[0]) + 11) % 12) + 1}:${
          Number(time_[1]) < 10 ? `0${Number(time_[1])}` : `${Number(time_[1])}`
        } ${Number(time_[0]) > 11 ? 'pm' : 'am'}`
    )

  return (
    <Layout seoTitle="Cortiz Fitness Sign Up">
      <animated.div
        style={springOpacity}
        className="grid grid-cols-2 bg-gray-100"
      >
        <div className="col-span-2 md:col-span-1">
          <div className="my-8 mx-4 rounded-md">
            <span className="flex justify-center text-2xl text-gray-600">
              Sign up for Cortiz Fitness
            </span>
            <p className="text-base bg-gray-100 rounded-md shadow-md leading-6 font-medium text-gray-500 p-4">
              Please select a plan or single session and let us know what your
              ideal start date and time slot would be. Optionally, you may
              include your ideal week days. This will enable us to pick a
              schedule that works best for you and your trainer.
            </p>

            <span className="mt-8 flex justify-center text-gray-500 text-lg">
              {pipe(
                plan,
                O.fold(
                  () => 'Select a service',
                  p =>
                    p === 'single_session'
                      ? 'Services included for a single session'
                      : `Services included in ${p} plan`
                )
              )}
            </span>
            <ServicesPerPlan />
          </div>
        </div>
        <div className="col-span-2 md:col-span-1">
          <div className="my-8 mx-4 bg-primary-600 shadow-md p-4 rounded-md grid grid-cols-2 gap-4">
            <div className="col-span-2">
              <span className="text-primary-100">Selected plan</span>
              <Select
                value={pipe(
                  plan,
                  O.fold(
                    () => undefined,
                    p =>
                      p == CF_PLAN.SINGLE_SESSION
                        ? { value: p, label: 'Single Session' }
                        : p == CF_PLAN.BASE
                        ? { value: p, label: 'Base' }
                        : p == CF_PLAN.PREMIUM
                        ? { value: p, label: 'Premium' }
                        : p == CF_PLAN.PLATINUM
                        ? { value: p, label: 'Platinum' }
                        : undefined
                  )
                )}
                options={cortizFitnessOptions}
                onChange={val => setPlan(O.some(val.value))}
                className="shadow-md w-auto z-10"
              />
            </div>
            <div className="col-span-2 md:col-span-1">
              <div className="flex flex-col">
                <div className="relative">
                  <span className="text-primary-100">Ideal Start Date</span>
                  <DatePicker
                    id="datePicker"
                    disabled={
                      O.isSome(idealStartDate1) &&
                      O.isSome(idealStartDate2) &&
                      O.isSome(idealStartDate3)
                    }
                    value={initialState.date}
                    onChange={value => {
                      const formatted = format(value, 'yyyy-MM-dd')
                      if (O.isNone(idealStartDate1)) {
                        setIdealStartDate1(
                          O.some({
                            date: value,
                            formatted,
                          })
                        )
                      } else if (O.isNone(idealStartDate2)) {
                        setIdealStartDate2(
                          O.some({
                            date: value,
                            formatted,
                          })
                        )
                      } else if (O.isNone(idealStartDate3)) {
                        setIdealStartDate3(
                          O.some({
                            date: value,
                            formatted,
                          })
                        )
                      }
                    }}
                    formatStyle="large"
                    locale="en-US"
                  />
                  <div className="mt-4 flex justify-end">
                    <span className="text-gray-300 text-xs">
                      choose up to three ideal dates
                    </span>
                  </div>
                  <div className="flex justify-between items-baseline">
                    <span
                      id="idealStartDate1"
                      title="use date selector above"
                      className={`form-input block w-full sm:text-sm sm:leading-5 rounded-md shadow-sm ${
                        O.isSome(idealStartDate1)
                          ? 'text-primary-400 bg-primary-700 border-primary-400'
                          : 'text-gray-400'
                      }`}
                    >
                      {pipe(
                        idealStartDate1,
                        O.fold(
                          () => `--/--/----`,
                          d1 => format(d1.date, 'MM/dd/yyyy')
                        )
                      )}
                    </span>
                    {O.isSome(idealStartDate1) && (
                      <div
                        className="text-primary-400 ml-2 cursor-pointer"
                        onClick={() => setIdealStartDate1(O.none)}
                      >
                        <svg
                          className="h-4 w-4"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <path d="M3 6l3 18h12l3-18h-18zm19-4v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.316c0 .901.73 2 1.631 2h5.711z" />
                        </svg>
                      </div>
                    )}
                  </div>
                  <div className="mt-4 flex justify-between items-baseline">
                    <span
                      id="idealStartDate2"
                      title="use date selector above"
                      className={`form-input block w-full sm:text-sm sm:leading-5 text-gray-400 rounded-md shadow-sm ${
                        O.isSome(idealStartDate2)
                          ? 'text-primary-400 bg-primary-700 border-primary-400'
                          : 'text-gray-400'
                      }`}
                    >
                      {pipe(
                        idealStartDate2,
                        O.fold(
                          () => `--/--/----`,
                          d2 => format(d2.date, 'MM/dd/yyyy')
                        )
                      )}
                    </span>
                    {O.isSome(idealStartDate2) && (
                      <div
                        className="text-primary-400 ml-2 cursor-pointer"
                        onClick={() => setIdealStartDate2(O.none)}
                      >
                        <svg
                          className="h-4 w-4"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <path d="M3 6l3 18h12l3-18h-18zm19-4v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.316c0 .901.73 2 1.631 2h5.711z" />
                        </svg>
                      </div>
                    )}
                  </div>
                  <div className="mt-4 flex justify-between items-baseline">
                    <span
                      id="idealStartDate3"
                      title="use date selector above"
                      className={`form-input block w-full sm:text-sm sm:leading-5 text-gray-400 rounded-md shadow-sm ${
                        O.isSome(idealStartDate3)
                          ? 'text-primary-400 bg-primary-700 border-primary-400'
                          : 'text-gray-400'
                      }`}
                    >
                      {pipe(
                        idealStartDate3,
                        O.fold(
                          () => `--/--/----`,
                          d3 => format(d3.date, 'MM/dd/yyyy')
                        )
                      )}
                    </span>
                    {O.isSome(idealStartDate3) && (
                      <div
                        className="text-primary-400 ml-2 cursor-pointer"
                        onClick={() => setIdealStartDate3(O.none)}
                      >
                        <svg
                          className="h-4 w-4"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <path d="M3 6l3 18h12l3-18h-18zm19-4v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.316c0 .901.73 2 1.631 2h5.711z" />
                        </svg>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-2 md:col-span-1">
              <span className="text-primary-100">Ideal Start Time</span>
              <TimePicker
                id="time-picker"
                value={pipe(idealStartTime1, O.toUndefined)}
                disabled={
                  O.isSome(idealStartTime1) &&
                  O.isSome(idealStartTime2) &&
                  O.isSome(idealStartTime3)
                }
                onChange={value => {
                  if (O.isNone(idealStartTime1)) {
                    setIdealStartTime1(O.some(parseMillitaryTime(value)))
                  } else if (O.isNone(idealStartTime2)) {
                    setIdealStartTime2(O.some(parseMillitaryTime(value)))
                  } else if (O.isNone(idealStartTime3)) {
                    setIdealStartTime3(O.some(parseMillitaryTime(value)))
                  }
                }}
              />
              <div className="mt-4 flex justify-end">
                <span className="text-gray-300 text-xs">
                  choose up to three ideal times
                </span>
              </div>
              <div className="relative">
                <div className="flex justify-between items-baseline">
                  <span
                    id="idealStartTime1"
                    title="use time selector above"
                    className={`form-input block w-full sm:text-sm sm:leading-5 text-gray-400 rounded-md shadow-sm ${
                      O.isSome(idealStartTime1)
                        ? 'text-primary-400 bg-primary-700 border-primary-400'
                        : 'text-gray-400'
                    }`}
                  >
                    {pipe(
                      idealStartTime1,
                      O.fold(
                        () => `--:-- --`,
                        t1 => t1
                      )
                    )}
                  </span>
                  {O.isSome(idealStartTime1) && (
                    <div
                      className="text-primary-400 ml-2 cursor-pointer"
                      onClick={() => setIdealStartTime1(O.none)}
                    >
                      <svg
                        className="h-4 w-4"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <path d="M3 6l3 18h12l3-18h-18zm19-4v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.316c0 .901.73 2 1.631 2h5.711z" />
                      </svg>
                    </div>
                  )}
                </div>
                <div className="mt-4 flex justify-between items-baseline">
                  <span
                    id="idealStartTime2"
                    title="use time selector above"
                    className={`form-input block w-full sm:text-sm sm:leading-5 text-gray-400 rounded-md shadow-sm ${
                      O.isSome(idealStartTime2)
                        ? 'text-primary-400 bg-primary-700 border-primary-400'
                        : 'text-gray-400'
                    }`}
                  >
                    {pipe(
                      idealStartTime2,
                      O.fold(
                        () => `--:-- --`,
                        t2 => t2
                      )
                    )}
                  </span>
                  {O.isSome(idealStartTime2) && (
                    <div
                      className="text-primary-400 ml-2 cursor-pointer"
                      onClick={() => setIdealStartTime2(O.none)}
                    >
                      <svg
                        className="h-4 w-4"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <path d="M3 6l3 18h12l3-18h-18zm19-4v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.316c0 .901.73 2 1.631 2h5.711z" />
                      </svg>
                    </div>
                  )}
                </div>
                <div className="mt-4 flex justify-between items-baseline">
                  <span
                    id="idealStartTime3"
                    title="use time selector above"
                    className={`form-input block w-full sm:text-sm sm:leading-5 text-gray-400 rounded-md shadow-sm ${
                      O.isSome(idealStartTime3)
                        ? 'text-primary-400 bg-primary-700 border-primary-400'
                        : 'text-gray-400'
                    }`}
                  >
                    {pipe(
                      idealStartTime3,
                      O.fold(
                        () => `--:-- --`,
                        t3 => t3
                      )
                    )}
                  </span>
                  {O.isSome(idealStartTime3) && (
                    <div
                      className="text-primary-400 ml-2 cursor-pointer"
                      onClick={() => setIdealStartTime3(O.none)}
                    >
                      <svg
                        className="h-4 w-4"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <path d="M3 6l3 18h12l3-18h-18zm19-4v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.316c0 .901.73 2 1.631 2h5.711z" />
                      </svg>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-span-2">
              <span className="text-primary-100">Ideal Week Days</span>
              <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-6 gap-2">
                <button
                  onClick={() => handleSelectedDay('monday')}
                  className={`flex justify-center p-2 shadow-sm rounded-md cursor-pointer focus:shadow-outline-orange focus:border-primary-900 focus:shadow-outline-primary-800 ${
                    hasSelectedDay('monday')
                      ? 'text-primary-600 bg-primary-400 active:bg-primary-400 active:text-primary-600 focus:bg-primary-400 focus:text-primary-600'
                      : 'text-gray-400 bg-gray-50'
                  }`}
                >
                  monday
                </button>
                <button
                  onClick={() => handleSelectedDay('tuesday')}
                  className={`flex justify-center p-2 shadow-sm rounded-md cursor-pointer focus:shadow-outline-orange focus:border-primary-900 focus:shadow-outline-primary-800 ${
                    hasSelectedDay('tuesday')
                      ? 'text-primary-600 bg-primary-400 active:bg-primary-400 active:text-primary-600 focus:bg-primary-400 focus:text-primary-600'
                      : 'text-gray-400 bg-gray-50'
                  }`}
                >
                  tuesday
                </button>
                <button
                  onClick={() => handleSelectedDay('wednesday')}
                  className={`flex justify-center p-2 shadow-sm rounded-md cursor-pointer focus:shadow-outline-orange focus:border-primary-900 focus:shadow-outline-primary-800 ${
                    hasSelectedDay('wednesday')
                      ? 'text-primary-600 bg-primary-400 active:bg-primary-400 active:text-primary-600 focus:bg-primary-400 focus:text-primary-600'
                      : 'text-gray-400 bg-gray-50'
                  }`}
                >
                  wednesday
                </button>
                <button
                  onClick={() => handleSelectedDay('thursday')}
                  className={`flex justify-center p-2 shadow-sm rounded-md cursor-pointer focus:shadow-outline-orange focus:border-primary-900 focus:shadow-outline-primary-800 ${
                    hasSelectedDay('thursday')
                      ? 'text-primary-600 bg-primary-400 active:bg-primary-400 active:text-primary-600 focus:bg-primary-400 focus:text-primary-600'
                      : 'text-gray-400 bg-gray-50'
                  }`}
                >
                  thursday
                </button>
                <button
                  onClick={() => handleSelectedDay('friday')}
                  className={`flex justify-center p-2 shadow-sm rounded-md cursor-pointer focus:shadow-outline-orange focus:border-primary-900 focus:shadow-outline-primary-800 ${
                    hasSelectedDay('friday')
                      ? 'text-primary-600 bg-primary-400 active:bg-primary-400 active:text-primary-600 focus:bg-primary-400 focus:text-primary-600'
                      : 'text-gray-400 bg-gray-50'
                  }`}
                >
                  friday
                </button>
                <button
                  onClick={() => handleSelectedDay('saturday')}
                  className={`flex justify-center p-2 shadow-sm rounded-md cursor-pointer focus:shadow-outline-orange focus:border-primary-900 focus:shadow-outline-primary-800 ${
                    hasSelectedDay('saturday')
                      ? 'text-primary-600 bg-primary-400 active:bg-primary-400 active:text-primary-600 focus:bg-primary-400 focus:text-primary-600'
                      : 'text-gray-400 bg-gray-50'
                  }`}
                >
                  saturday
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="pb-8 col-span-2 md:col-span-1 md:col-start-2">
          <div className="mb-8">
            <Elements stripe={stripePromise}>
              <CfPaymentForm
                plan={plan}
                isCfPlan
                selectedAlternativePrice={O.none}
                idealStartDate1={idealStartDate1}
                idealStartDate2={idealStartDate2}
                idealStartDate3={idealStartDate3}
                idealStartTime1={pipe(
                  idealStartTime1,
                  O.map(t => t.toUpperCase())
                )}
                idealStartTime2={pipe(
                  idealStartTime2,
                  O.map(t => t.toUpperCase())
                )}
                idealStartTime3={pipe(
                  idealStartTime3,
                  O.map(t => t.toUpperCase())
                )}
                idealDaysOfTheWeek={idealDaysOfTheWeek}
              />
            </Elements>
          </div>
        </div>
      </animated.div>
    </Layout>
  )
}

export default CfSignUp
